import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc, doc, onSnapshot } from "firebase/firestore";

// Ensure this is your live mode publishable key
const stripePromise = loadStripe("pk_test_51OAzMpFsrZfaVfgkWcoAnt9yZ7lvVD5LjaRej7oR9p2A9zGysPFLUeyULt7xt5Sk2lKYB83f6I37PGicBXpccM030010vY9H81");

const SubscribeButton = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
    }
  }, []);

  const handleSubscribe = async () => {
    if (!userId) {
      console.error("User is not authenticated.");
      return;
    }

    const db = getFirestore();
    const checkoutSessionRef = collection(db, `customers/${userId}/checkout_sessions`);
    try {
      const docRef = await addDoc(checkoutSessionRef, {
        price: "price_1OyBeiFsrZfaVfgkoQSwPimP", // Ensure this matches your live mode price ID
        success_url: window.location.origin + "/dashboard",
        cancel_url: window.location.origin + "/",
      });

      onSnapshot(doc(db, `customers/${userId}/checkout_sessions`, docRef.id), async (doc) => {
        const { sessionId } = doc.data();
        if (sessionId) {
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({ sessionId });
          if (error) {
            console.error("Stripe Checkout error:", error.message);
          }
        }
      });
    } catch (error) {
      console.error("Failed to create checkout session:", error);
    }
  };

  return <button className="subscribe-btn" onClick={handleSubscribe}>Subscribe</button>;
};

export default SubscribeButton;
