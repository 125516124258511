import React, { useState, useEffect, useCallback } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import Accordion from "./components/Accordion";
import "./Dashboard.css";
import { List, Trash } from "react-bootstrap-icons";
import SubscribeButton from "./components/SubscribeButton";
import ManageSubscriptionButton from "./components/ManageSubscriptionButton";
import { useReforge } from "./contexts/ReforgeContext";

const Dashboard = () => {
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [message, setMessage] = useState("");
  const [userContents, setUserContents] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [activeSection, setActiveSection] = useState("new");
  const [orderedTitles, setOrderedTitles] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReforgeInProgress, setIsReforgeInProgress] = useState(false);
  const [isPaidSubscriber, setIsPaidSubscriber] = useState(false);
  const [reforgeCounter, setReforgeCounter] = useState(0);
  const [isUserContentsLoading, setIsUserContentsLoading] = useState(true);
  const [floatingNotice, setFloatingNotice] = useState({
    message: "",
    type: "",
  });

  // Extract reforgeState and setReforgeState from useReforge
  const { reforgeState, setReforgeState } = useReforge();

  // isLoading should now be derived from reforgeCounter
  const isLoading = reforgeCounter > 0;

  useEffect(() => {
    if (reforgeState.isReforgeInProgress) {
      // Logic to handle UI updates for "Reforging..."
      setIsReforgeInProgress(true);

      // Optionally, fetch or update content list in Navbar
    }
  }, [reforgeState]); // Correctly use reforgeState here

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const fetchUserContents = useCallback(async (userId) => {
    setIsUserContentsLoading(true);
    try {
      const response = await fetch(
        `https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/user-content/${userId}`
      );
      const data = await response.json();
      const titles = data.map((item) => item.title);
      setOrderedTitles(titles);
      const updatedUserContents = data.map((item) => {
        if (item.transcriptPath) {
          return { ...item, transcriptPath: item.transcriptPath };
        }
        return item;
      });
      setUserContents(updatedUserContents);
    } catch (error) {
      console.error("Error fetching user content:", error);
      setMessage("Error fetching user content");
    }
    setIsUserContentsLoading(false);
  }, []);

  const checkSubscriptionStatus = async (userId) => {
    try {
      const response = await fetch(
        `https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/check-subscription/${userId}`
      );
      if (!response.ok) {
        throw new Error("Failed to check subscription status");
      }
      const data = await response.json();
      return data.isSubscriber;
    } catch (error) {
      console.error("Error checking subscription status:", error);
      return false;
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isSubscriber = await checkSubscriptionStatus(user.uid);
        setIsPaidSubscriber(isSubscriber);
      }
    });
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    setPersistence(auth, browserLocalPersistence);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserContents(user.uid);
      }
    });
  }, [fetchUserContents, reforgeState]);

  const handleUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
  };

  const handleExtractMp3 = async () => {
    // Start the reforge process and indicate loading
    setReforgeState({ ...reforgeState, isReforgeInProgress: true });
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // If no user is found, stop the reforge process and reset the loading state
      setReforgeState({ ...reforgeState, isReforgeInProgress: false });
      return;
    }

    try {
      const response = await fetch(
        "https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/extract-mp3",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ youtubeUrl, userId: user.uid }),
        }
      );

      const data = await response.json();

      if (data.success) {
        // On success, update the UI accordingly
        const newTitle = data.fileName; // Assuming the response contains the fileName or title
        setOrderedTitles([newTitle, ...orderedTitles]);

        fetchUserContents(user.uid).then(() => {
          const newContent = userContents.find((c) => c.title === newTitle);
          if (newContent) {
            setSelectedContent(newContent);
            setActiveSection("content");
          }
        });

        // Display success message
        setReforgeState({
          ...reforgeState,
          isReforgeInProgress: false,
          reforgeMessage: {
            message: "New content generated.",
            type: "success",
          },
        });
      } else {
        // On failure, display error message
        setReforgeState({
          ...reforgeState,
          isReforgeInProgress: false,
          reforgeMessage: {
            message:
              data.message ||
              "Error extracting content. Please check your input URL.",
            type: "error",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle fetch error
      setReforgeState({
        ...reforgeState,
        isReforgeInProgress: false,
        reforgeMessage: { message: "Failed to extract MP3", type: "error" },
      });
    }

    // Reset the input field after process completion
    setYoutubeUrl("");
  };

  const handleContentClick = async (content) => {
    try {
      let transcriptText = "";
      let blogPosts = [];

      if (content.transcriptPath) {
        const transcriptResponse = await fetch(
          `https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/fetch-content/${encodeURIComponent(
            content.transcriptPath
          )}`
        );
        transcriptText = await transcriptResponse.text();
      }

      if (content.contentPaths && content.contentPaths.length > 0) {
        blogPosts = await Promise.all(
          content.contentPaths.map(async (path) => {
            const response = await fetch(
              `https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/fetch-content/${encodeURIComponent(
                path
              )}`
            );
            return await response.text();
          })
        );
      }

      setSelectedContent({
        title: content.title,
        transcript: transcriptText,
        blogPosts: blogPosts,
        contentPaths: content.contentPaths, // Ensure this array is correctly set
        transcriptPath: content.transcriptPath,
      });
    } catch (error) {
      console.error("Error fetching content:", error);
      setMessage("Error fetching content");
    }
    if (window.innerWidth < 768) {
      setIsMenuOpen(false);
    }
  };

  const handleDeleteContent = async (contentTitle) => {
    if (window.confirm(`Are you sure you want to delete "${contentTitle}"?`)) {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return;

      try {
        const folderName = contentTitle.replace(/ /g, "_"); // Construct folder name
        const response = await fetch(
          `https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/delete-content/${user.uid}/${folderName}`,
          {
            method: "DELETE",
          }
        );

        const data = await response.json();

        if (data.success) {
          // Manually update the state to remove the deleted item
          const updatedContents = userContents.filter(
            (content) => content.title !== contentTitle
          );
          setUserContents(updatedContents);

          // Navigate to "New" section
          setActiveSection("new");

          alert(`"${contentTitle}" has been deleted.`);
        } else {
          alert("Error: Unable to delete content");
        }
      } catch (error) {
        console.error("Error deleting content:", error);
        alert("Failed to delete content");
      }
    }
  };

  const generateBlogPost = async (transcriptText, transcriptPath) => {
    setReforgeCounter((prev) => prev + 1);
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      setReforgeCounter((prev) => prev - 1);
      return;
    }

    if (!transcriptPath) {
      console.error("Transcript path is undefined");
      setFloatingNotice({
        message: "Error: Transcript path is undefined",
        type: "error",
      });
      setReforgeCounter((prev) => prev - 1);
      return;
    }

    try {
      const response = await fetch(
        "https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/generate-blog-post",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            transcriptText,
            userId: user.uid,
            transcriptPath,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        const blogPostResponse = await fetch(
          `https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/fetch-content/${encodeURIComponent(
            data.blogPostFileName
          )}`
        );
        const newBlogPostContent = await blogPostResponse.text();

        setSelectedContent((prevContent) => ({
          ...prevContent,
          blogPosts: [...(prevContent.blogPosts || []), newBlogPostContent],
          contentPaths: [
            ...(prevContent.contentPaths || []),
            data.blogPostFileName,
          ],
        }));

        setUserContents((prevUserContents) =>
          prevUserContents.map((content) => {
            if (content.title === selectedContent.title) {
              return {
                ...content,
                contentPaths: [
                  ...(content.contentPaths || []),
                  data.blogPostFileName,
                ],
                blogPosts: [...(content.blogPosts || []), newBlogPostContent],
              };
            }
            return content;
          })
        );

        setFloatingNotice({
          message: `New blog post generated for ${selectedContent.title}`,
          type: "success",
        });
      } else if (data.message.includes("SAFETY")) {
        setFloatingNotice({
          message:
            "Content generation blocked for safety reasons. Please modify the content and try again.",
          type: "error",
        });
      } else {
        setFloatingNotice({
          message: data.message || "Error generating blog post",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setFloatingNotice({
        message: "Failed to generate blog post",
        type: "error",
      });
    }

    setReforgeCounter((prev) => prev - 1);
    setYoutubeUrl(""); // Clear the input field after process completion
  };

  const deleteBlogPost = async (blogPostPath, contentTitle) => {
    if (window.confirm("Are you sure you want to delete this blog post?")) {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return;

      try {
        const response = await fetch(
          `https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/delete-blog-post/${blogPostPath}`,
          { method: "DELETE" }
        );

        const data = await response.json();
        if (data.success) {
          setUserContents((prevUserContents) =>
            prevUserContents.map((content) => {
              if (content.title === contentTitle) {
                const updatedContentPaths = content.contentPaths
                  ? content.contentPaths.filter((path) => path !== blogPostPath)
                  : [];
                const updatedBlogPosts = content.blogPosts
                  ? content.blogPosts.filter((post) => post !== blogPostPath)
                  : [];
                return {
                  ...content,
                  contentPaths: updatedContentPaths,
                  blogPosts: updatedBlogPosts,
                };
              }
              return content;
            })
          );

          if (selectedContent && selectedContent.title === contentTitle) {
            const updatedSelectedContentPaths = selectedContent.contentPaths
              ? selectedContent.contentPaths.filter(
                  (path) => path !== blogPostPath
                )
              : [];
            const updatedSelectedBlogPosts = selectedContent.blogPosts
              ? selectedContent.blogPosts.filter(
                  (post) => post !== blogPostPath
                )
              : [];
            setSelectedContent({
              ...selectedContent,
              contentPaths: updatedSelectedContentPaths,
              blogPosts: updatedSelectedBlogPosts,
            });
          }

          alert("Blog post deleted successfully.");
          window.location.reload();
        } else {
          alert("Error: Unable to delete blog post");
        }
      } catch (error) {
        console.error("Error deleting blog post:", error);
        alert("Failed to delete blog post");
      }
    }
  };

  const clearFloatingNotice = () => {
    setFloatingNotice({ message: "", type: "" });
  };

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // navigate to login page or handle sign out
      })
      .catch((error) => {
        console.error("Sign Out Error", error);
      });
  };

  return (
    <div className="dashboard">
      {isReforgeInProgress}
      {floatingNotice.message && (
        <div
          className={`floating-notice ${
            floatingNotice.type === "success" ? "success" : "error"
          }`}
          onAnimationEnd={clearFloatingNotice}
        >
          {floatingNotice.message}
        </div>
      )}
      <button className="menu-toggle" onClick={toggleMenu}>
        <List />
      </button>
      <div className="content-area">
        <div className={`left-sidebar ${isMenuOpen ? "expanded" : ""}`}>
          <div className="reforge-title">Reforge AI</div>
          <ul>
            {isUserContentsLoading ? (
              <li className="loading">Loading contents...</li>
            ) : (
              <>
                <li
                  className="content-item new-content-item"
                  onClick={() => setActiveSection("new")}
                >
                  New
                  <span className="flame-icon" role="img" aria-label="flame">
                    🔥
                  </span>
                </li>
                {orderedTitles.map((title, index) => {
                  const content = userContents.find((c) => c.title === title);
                  if (!content) {
                    return null; // Skip rendering if content is not found
                  }
                  return (
                    <div key={index} className="content-item">
                      <span
                        className="content-title"
                        onClick={() => {
                          handleContentClick(content);
                          setActiveSection("content");
                        }}
                      >
                        {content.title}
                      </span>
                      <Trash
                        onClick={() => handleDeleteContent(content.title)}
                        className="delete-icon"
                      />
                    </div>
                  );
                })}
              </>
            )}
          </ul>

          <div className="sign-out-btn-container">
            {isPaidSubscriber ? (
              <ManageSubscriptionButton />
            ) : (
              <SubscribeButton />
            )}
            <button onClick={handleSignOut} className="sign-out-btn">
              Sign Out
            </button>
          </div>
        </div>
        <div className="main-content">
          {reforgeState.reforgeMessage.message && (
            <div
              className={`floating-notice ${reforgeState.reforgeMessage.type}`}
            >
              {reforgeState.reforgeMessage.message}
            </div>
          )}
          {activeSection === "new" && (
            <>
              <div className="youtube-input-section">
                <div className="youtube-input-header">
                  <h1 className="youtube-input-headline">
                    Reforge YouTube Videos Into Blog Posts
                  </h1>
                  <p className="youtube-input-subline">
                    Paste a YouTube link to extract its content, and watch
                    Reforge craft it into an SEO-optimized article, ready to
                    boost your rankings.
                  </p>
                  <input
                    type="text"
                    className="youtube-input"
                    value={youtubeUrl}
                    onChange={handleUrlChange}
                    placeholder="Enter YouTube URL"
                    disabled={isLoading}
                  />
                  <button
                    className={`youtube-button ${
                      reforgeState.isReforgeInProgress ? "loading" : ""
                    }`}
                    onClick={handleExtractMp3}
                    disabled={reforgeState.isReforgeInProgress}
                  >
                    {reforgeState.isReforgeInProgress
                      ? "Reforging..."
                      : "Reforge"}
                  </button>
                </div>
              </div>
              {message && <p>{message}</p>}
            </>
          )}
          {selectedContent && activeSection === "content" && (
            <div className="content-display">
              <h2 className="youtube-video-title">{selectedContent.title}</h2>
              <Accordion
                title="Transcript"
                content={selectedContent.transcript}
                id="transcript"
                isOpen={true}
                isTranscript={true}
                isLoading={isLoading}
                onGenerateBlogPost={() =>
                  generateBlogPost(
                    selectedContent.transcript,
                    selectedContent.transcriptPath
                  )
                }
                transcriptPath={selectedContent.transcriptPath}
              />
              {selectedContent.blogPosts.map((post, index) => (
                <Accordion
                  key={`${selectedContent.contentPaths[index]}-${index}`}
                  title={`Blog Post #${index + 1}`}
                  content={post}
                  id={`blogPost-${index}`}
                  isOpen={true}
                  onDeleteBlogPost={() =>
                    deleteBlogPost(
                      selectedContent.contentPaths[index],
                      selectedContent.title
                    )
                  }
                  contentPaths={selectedContent.contentPaths}
                  index={index}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
