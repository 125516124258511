import React from 'react';

const ManageSubscriptionButton = () => {
  const handleManageSubscription = () => {
    window.location.href = "https://billing.stripe.com/p/login/test_cN27vsdQ32d37zq5kk";
  };

  return (
    <button className="manage-btn" onClick={handleManageSubscription}>
      Manage Subscription
    </button>
  );
};

export default ManageSubscriptionButton;
