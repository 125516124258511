import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from "./firebaseConfig";
import Login from "./components/Login";
import Dashboard from "./Dashboard";
import { Helmet } from "react-helmet";
import { ReforgeProvider } from './contexts/ReforgeContext';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Reforge AI - Turn Your YouTube Content Into SEO Articles 1-Click
        </title>
        <meta
          name="description"
          content="Reforge AI transforms your YouTube videos into engaging, SEO-optimized blog articles with ease. Discover the power of automated content repurposing."
        />
        <link rel="canonical" href="https://reforgeapp.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="author" content="Reforge Content Co." />
        <meta
          name="keywords"
          content="youtube, youtube shorts, seo, articles, blog, content generation, content repurposing, AI"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="https://reforgeapp.com/sitemap.xml"
        />

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://reforgeapp.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Your YouTube Content Into SEO Articles (and more) at a Distance of a Click!"
        />
        <meta
          property="og:description"
          content="Reforge AI transforms your YouTube videos into engaging, SEO-optimized blog articles with ease. Discover the power of automated content repurposing."
        />
        <meta property="og:image" content="/og.png" />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Your YouTube Content Into SEO Articles (and more) at a Distance of a Click!"
        />
        <meta
          name="twitter:description"
          content="Reforge AI transforms your YouTube videos into engaging, SEO-optimized blog articles with ease. Discover the power of automated content repurposing."
        />
        <meta name="twitter:image" content="/og.png" />
      </Helmet>

      <ReforgeProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
          />
          <Route
            path="/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
          />
        </Routes>
      </Router>
      
    </ReforgeProvider>
    </>
  );
};

export default App;
