import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const getFirebaseConfig = async () => {
  const response = await fetch(
    "https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/firebase-config"
  );
  return response.json();
};

const initFirebaseApp = async () => {
  const firebaseConfig = await getFirebaseConfig();
  return initializeApp(firebaseConfig);
};

const app = await initFirebaseApp();
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };
export default app;
