import React, { useState, useEffect } from "react";
import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
} from "firebase/auth";
import app from "../firebaseConfig"; // Adjust this import path as necessary
import "./Login.css";
import { useReforge } from "../contexts/ReforgeContext";

const Login = () => {
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const { reforgeState, setReforgeState } = useReforge();
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    getRedirectResult(auth)
      .then((result) => {
        if (result && result.user) {
          const url = localStorage.getItem("youtubeUrl");
          if (url) {
            extractMp3(url, result.user.uid);
            localStorage.removeItem("youtubeUrl");
          }
        }
      })
      .catch((error) => {
        console.error("Error obtaining redirect result:", error);
      });
  }, []);

  const signInWithGoogle = () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const handleReforge = () => {
    if (!youtubeUrl) {
      alert("Please enter a YouTube URL.");
      return;
    }

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    localStorage.setItem("youtubeUrl", youtubeUrl);
    signInWithRedirect(auth, provider);
  };

  const extractMp3 = (url, userId) => {
    console.log(
      `Attempting to extract MP3 for URL: ${url} and user ID: ${userId}`
    );
    setReforgeState({ ...reforgeState, isReforgeInProgress: true });

    fetch(
      "https://us-central1-reforge-ai-webapp.cloudfunctions.net/api/extract-mp3",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ youtubeUrl: url, userId }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Extraction successful:", data);
        setReforgeState({
          isReforgeInProgress: false,
          youtubeUrl: "",
          reforgeMessage: data.success
            ? { message: "Content successfully extracted.", type: "success" }
            : {
                message: data.message || "Failed to extract content.",
                type: "error",
              },
        });
      })
      .catch((error) => {
        console.error("Extraction failed:", error);
        setReforgeState({
          isReforgeInProgress: false,
          youtubeUrl: "",
          reforgeMessage: { message: "Failed to extract MP3", type: "error" },
        });
      });
  };

  return (
    <div className="login-main-container">
      <div className="sign-in-btn-container">
        <button onClick={signInWithGoogle} className="sign-in-btn">
          Sign In
        </button>
      </div>
      <div className="login-container">
        <h1 className="reforge-headline">
          REFORGE AI 🔥 <span className="beta-tag">BETA</span>
        </h1>
        <div className="login-header">
          <h1 className="login-headline">
            <span className="headline-gradient">One Video</span>
            <br />
            <span className="headline-contents">Hundreds Of Contents</span>
          </h1>
          <p className="login-subline">
            Craft SEO Articles from YouTube videos instantly. Forge ahead with
            posts that sparkle with context.
          </p>{" "}
          <div
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            onMouseEnter={() => setIsInputFocused(true)}
            onMouseLeave={() => setIsInputFocused(false)}
          >
            <div className="input-button-container">
              <input
                type="text"
                placeholder="Enter YouTube URL"
                value={youtubeUrl}
                onChange={(e) => setYoutubeUrl(e.target.value)}
                className="youtube-input-login"
              />
              <button onClick={handleReforge} className="login-button">
                Reforge
              </button>
            </div>
            {isInputFocused && (
              <div className="login-message">
                Already a subscriber?{" "}
                <button onClick={signInWithGoogle} className="sign-here">
                  Sign In here
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="trustpilot-rating">
          <a
            href="https://www.trustpilot.com/review/reforgeapp.com"
            target="_blank"
            rel="noopener noreferrer"
            className="trustpilot-link" // Ensure this class only affects the <a> tag without introducing new styling
          >
            <span className="trustpilot-bold">Excellent 5</span>out of 5
            <img
              src={require("./images/trustpilot.svg").default}
              alt="TrustPilot"
            />
            <span className="trustpilot-bold">TrustPilot</span>
          </a>
        </div>

        <div className="featured-in">
          <div className="logos">
            <a
              href="https://ahrefs.com/blog/video-marketing/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/ahrefs.svg").default} alt="Ahrefs" />
            </a>
            <a
              href="https://www.searchenginejournal.com/repurposing-content/483605/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/sej.svg").default} alt="SEJ" />
            </a>
            <a
              href="https://garyvaynerchuk.com/how-to-create-64-pieces-of-content-in-a-day/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/garyvee.svg").default}
                alt="GaryVee"
              />
            </a>
            <a
              href="https://www.forbes.com/sites/theyec/2020/04/09/repurposing-how-to-transform-a-single-video-into-multiple-pieces-of-content/?sh=51fce757515e"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/forbes.svg").default} alt="Forbes" />
            </a>
          </div>
        </div>
      </div>

      <div className="screenshot-container">
        <img src={require("./images/screenshot1.png")} alt="Feature 1" />
        <div>
          <h2>Create, Reforge, Grow</h2>
          <p className="subline">No More Bland AI Content</p>
          <p>
            Transform any YouTube video into an abundance of SEO-optimized
            articles. Enrich your content strategy with unparalleled depth,
            leveraging the finest sources from across the web.
          </p>
        </div>
      </div>

      <div className="screenshot-container">
        <img src={require("./images/screenshot2.png")} alt="Feature 2" />
        <div>
          <h2>Record Once, Publish Endlessly</h2>
          <p className="subline">Scale Content with Minimal Effort</p>
          <p>
            Reforge your video content into captivating, SEO-driven articles
            that resonate with authenticity. Enjoy seamless readiness for any
            CMS, ensuring your articles are primed for publication.
          </p>
        </div>
      </div>

      <div className="screenshot-container">
        <img src={require("./images/screenshot3.png")} alt="Feature 3" />
        <div>
          <h2>Watch it Burn</h2>
          <p className="subline">Ignite Your Creative Spark</p>
          <p>
            Embark on your content creation journey today without any upfront
            costs. Start forging today—no credit card, just creativity for 7
            days. Created by AI content pioneers.
          </p>
        </div>
      </div>

      {/* Pricing container */}
      <div className="pricing-container">
        <div className="price-box">
          <h2>Get Started for Just $7/Month</h2>
          <p>Unlock full access with a 24-hour free trial.</p>
          <button onClick={signInWithGoogle}>START FREE TRIAL</button>
        </div>
      </div>

      <footer className="login-footer">
        <p>
          &copy; {new Date().getFullYear()} Reforge App. All rights reserved.
        </p>
        <a href="/terms-of-use.html" target="_blank" rel="noopener noreferrer">
          Terms of Use
        </a>{" "}
        |{" "}
        <a
          href="/privacy-policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

export default Login;
