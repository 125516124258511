import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { ChevronDown, ChevronUp, Trash } from "react-bootstrap-icons";
import "./Accordion.css";
import { useReforge } from "../contexts/ReforgeContext"; // Adjust the import path as necessary

const Accordion = ({
  title,
  content,
  id,
  isTranscript,
  onGenerateBlogPost,
  transcriptPath,
  onDeleteBlogPost,
  contentPaths,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef(null);
  const [viewMarkdown, setViewMarkdown] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  const { reforgeState, setReforgeState } = useReforge(); // Correctly placed inside the component

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      // Trigger a reflow to recalculate the scrollHeight
      contentRef.current.style.maxHeight = "none";
      const scrollHeight = contentRef.current.scrollHeight;
      contentRef.current.style.maxHeight = isOpen ? `${scrollHeight}px` : "0px";
    }
  }, [isOpen, content]);

  const handleSwitchChange = () => {
    setViewMarkdown(!viewMarkdown);
  };

  const handleCopyContent = () => {
    let contentToCopy = "";
    if (viewMarkdown) {
      contentToCopy = content;
    } else {
      const htmlContent = document.querySelector(
        `.markdown-html-${id}`
      ).innerHTML;
      contentToCopy = htmlContent;
    }
    navigator.clipboard.writeText(contentToCopy);
    setShowCopyConfirmation(true);
    setTimeout(() => setShowCopyConfirmation(false), 2000);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={toggleAccordion}>
        {isOpen ? (
          <ChevronUp className="accordion-icon" />
        ) : (
          <ChevronDown className="accordion-icon" />
        )}
        <h2>{title}</h2>
      </div>
      <div
        className={`accordion-content ${isOpen ? "open" : ""}`}
        ref={contentRef}
      >
        <div className="accordion-controls">
          <div className="controls-left">
            <div className="format-switch">
              <button
                className={`format-button ${!viewMarkdown ? "active" : ""}`}
                onClick={handleSwitchChange}
              >
                HTML
              </button>
              <button
                className={`format-button ${viewMarkdown ? "active" : ""}`}
                onClick={handleSwitchChange}
              >
                Markdown
              </button>
            </div>
          </div>
          <div className="controls-right">
            {isTranscript && (
              <button
                className={`generate-blogpost-button ${
                  reforgeState.isReforgeInProgress ? "loading" : ""
                }`}
                onClick={() => {
                  // Example of setting the context state to true at the start of the process
                  setReforgeState((prevState) => ({
                    ...prevState,
                    isReforgeInProgress: true,
                  }));

                  // Call the actual article generation function
                  onGenerateBlogPost(content, transcriptPath)
                    .then(() => {
                      // Set isReforgeInProgress to false upon success
                      setReforgeState((prevState) => ({
                        ...prevState,
                        isReforgeInProgress: false,
                      }));
                    })
                    .catch(() => {
                      // Handle errors and set isReforgeInProgress to false upon failure
                      setReforgeState((prevState) => ({
                        ...prevState,
                        isReforgeInProgress: false,
                      }));
                    });
                }}
                disabled={reforgeState.isReforgeInProgress}
              >
                {reforgeState.isReforgeInProgress
                  ? "Reforging..."
                  : "Generate Article"}
              </button>
            )}
            <button onClick={handleCopyContent} className="copy-button">
              Copy
            </button>
            {!isTranscript && (
              <button
                onClick={() => onDeleteBlogPost(contentPaths[index])}
                className="delete-blogpost-button"
              >
                <Trash />
              </button>
            )}
          </div>
        </div>
        <div className={`markdown-html markdown-html-${id}`}>
          {viewMarkdown ? (
            <pre>{content}</pre>
          ) : (
            <ReactMarkdown>{content}</ReactMarkdown>
          )}
        </div>
        {showCopyConfirmation && (
          <div className="copy-confirmation">Content copied!</div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
