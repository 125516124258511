import React, { createContext, useState, useContext } from "react";

const ReforgeContext = createContext();

export const useReforge = () => useContext(ReforgeContext);

export const ReforgeProvider = ({ children }) => {
  const [reforgeState, setReforgeState] = useState({
    isReforgeInProgress: false,
    youtubeUrl: "",
    reforgeMessage: { message: "", type: "" }, // Add this line
  });

  return (
    <ReforgeContext.Provider value={{ reforgeState, setReforgeState }}>
      {children}
    </ReforgeContext.Provider>
  );
};
